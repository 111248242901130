import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuiIcon from "components/IconComponent/AuiIcon";
import SectionList from "./SectionList";
import {
  setActiveFilter,
  setCount,
  setDeleteReport,
  setShouldFetchFilter,
  setShowMyReports,
  setShowPopup,
} from "./LeftPanelSlice";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const MyReportFilters = (props: any) => {
  const leftPanelState = useSelector((state: any) => {
    return state.adminLeftPanel;
  });
  const [active, setActive] = useState();
  const routeParams: any = useParams();
  useEffect(() => {
    if (routeParams?.id) {
      setActive(routeParams?.id);
    }
  }, [routeParams]);

  const dispatch = useDispatch();
  const {
    adimnReportList,
    activityReportList,
    handleDeleFilter,
    isSavedFilterUpdated,
  } = props;
  const { t } = useTranslation();

  const DATA = [
    {
      title: "Admin Reports",
      data: adimnReportList,
    },
    {
      title: "Activity Reports",
      data: activityReportList,
    },
  ];
  const handleFilterSelect = (item: any) => {
    dispatch(setCount())
    dispatch(setActiveFilter(item));
  };

  return (
    <SectionList
      sections={DATA}
      renderItem={(item: any) => (
        <CSSTransition
          key={item.id}
          timeout={500}
          classNames="item"
          in={true}
          appear={true}
        >
          <li className={classNames("filter-li ")}>
            <Link
              to={{
                pathname: `/custom-reports/${item.id}`,
                state: { isSavedFilter: true },
              }}
              onClick={(e) => {
                dispatch(setShouldFetchFilter(true));
                let isUpdated = isSavedFilterUpdated();
                if (isUpdated) {
                  e.preventDefault();
                  dispatch(setShowPopup(true));
                  props?.setPopupData({ item: item });
                } else {
                  handleFilterSelect(item);
                }
              }}
              className={`${(leftPanelState?.activeFilter?.id == item?.id ||
                active == item?.id) &&
                "active"
                }`}
              data-testid="delete-report-btn"
              data-toggle={leftPanelState.showPopup ? "modal" : ""}
              data-target={"#customReportWarningModal"}
            >
              {item.name}
            </Link>
            <span
              className="delete-icon"
              data-testid="delete-report-btn"
              data-toggle={"modal"}
              data-target={"#modalDeleteReport"}
              onClick={() => handleDeleFilter(item.id)}
            >
              <AuiIcon
                elementObject={{
                  svgclass: "aui-icon-trash text-primary",
                  pathclass: "aui-path-trash",
                  icon: "trash",
                  svgwidth: "16",
                  svgheight: "16",
                }}
              ></AuiIcon>
            </span>
          </li>
        </CSSTransition>
      )}
      renderSectionHeader={(title: any) => (
        <span className="section-header-text">{title}</span>
      )}
    />
  );
};

export default MyReportFilters;
